'use client';

import { GlobalProgressBar } from '@/components/ui/global-progress';
import { Toaster } from '@/components/ui/sonner';
import config from '@/config';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { type ReactNode } from 'react';
import GlobalJotaiProvider, { HydrationData } from './-jotai';
import Timezone from './-timezone';
import { TooltipProvider } from '@/components/ui/tooltip';
import IntercomProvider from './-intercom';
import Intercom from '@intercom/messenger-js-sdk';
if (typeof window !== 'undefined') {
  if (config.analytics.posthog.enabled) {
    posthog.init(config.analytics.posthog.key, {
      api_host: config.analytics.posthog.host
    });
  }
  if (config.environment.isProduction) {
    Intercom({
      api_base: config.intercomConfig.apiBase,
      app_id: config.intercomConfig.appId
    });
  }
}
export default function Providers({
  children,
  ...hydrationData
}: HydrationData & {
  children: ReactNode;
}) {
  return <>
      <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-source-file="-providers.tsx">
        <GlobalJotaiProvider {...hydrationData} data-sentry-element="GlobalJotaiProvider" data-sentry-source-file="-providers.tsx">
          <GlobalProgressBar data-sentry-element="GlobalProgressBar" data-sentry-source-file="-providers.tsx">
            <Timezone data-sentry-element="Timezone" data-sentry-source-file="-providers.tsx">
              <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-source-file="-providers.tsx">
                <IntercomProvider {...hydrationData} data-sentry-element="IntercomProvider" data-sentry-source-file="-providers.tsx" />
                {children}
              </TooltipProvider>
              <Toaster data-sentry-element="Toaster" data-sentry-source-file="-providers.tsx" />
            </Timezone>
          </GlobalProgressBar>
        </GlobalJotaiProvider>
      </PostHogProvider>
    </>;
}